import React from 'react';
import Link from 'next/link';

import TitleSection from '../../atom/Title/TitleSection';
import { MotionComponent } from '../../atom/MotionComponent';
import appRoutes from '../../../core/routes';
import GameProviderImage from '../../atom/GameProviderImage';
import useGameStore from '@/context/store/useGameStore.store';

const GameProviderComponent2 = ({ slideMode, showTitle = false }) => {
	const { gameProviderData } = useGameStore();

	return (
		<React.Fragment>
			<MotionComponent slideMode={slideMode}>
				<div className="flex flex-col mb-section">
					{showTitle && <TitleSection title="Game Provider" />}
					<div className="flex gap-2 overflow-x-auto overflow-hidden scrollbar-none">
						{gameProviderData?.map((item, index) => (
							<div className="w-full h-full" key={index}>
								<div className="w-32">
									<Link
										href={{
											pathname: appRoutes.PROVIDER,
											query: {
												gp: item?.gpid,
												gpname: item?.gpname
											}
										}}>
										<a>
											<div
												className={`
												flex w-full h-full items-center justify-center text-center aspect-[2/1] rounded-lg cursor-pointer
												bg-gradient-to-b from-container-bg-accent2 to-container-bg-accent3 p-2
											`}>
												{item?.gplogo ? (
													<>
														<GameProviderImage
															gameCategoryName={item?.gpid}
															className={'w-full h-full'}
														/>
													</>
												) : (
													<>
														<span className="text-container-color-primary">{item?.gpname}</span>
													</>
												)}
											</div>
										</a>
									</Link>
								</div>
							</div>
						))}
					</div>
				</div>
			</MotionComponent>
		</React.Fragment>
	);
};

export default GameProviderComponent2;
